import { FC, HTMLAttributes } from 'react'

import { cva } from 'class-variance-authority'

import { twMerge } from '~/lib/utils'

type Color = 'black' | 'brand'

type SectionHeadingProps = { color?: Color } & Omit<
  HTMLAttributes<HTMLHeadingElement>,
  'color'
>

const variants = cva(null, {
  variants: {
    color: {
      black: null,
      brand: 'text-brand',
    },
  },
})

export const SectionHeading: FC<SectionHeadingProps> = ({
  color = 'black',
  className,
  children,
  ...props
}) => {
  return (
    <h2
      className={twMerge(
        'text-[20px] font-bold leading-[140%] md:text-[24px]',
        variants({ color }),
        className,
      )}
      {...props}
    >
      {children}
    </h2>
  )
}
